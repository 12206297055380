import React from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

import inject from '../../../../../hoc/injectHoc';

import {FEATURES_TABLE_DATA} from './NextManufacturerConstants';
import logo from '../../../../../../assets/images/logo.png';
import PlanItem from '../../../../../../components/common/subscriptionPlanTable/components/planItem/PlanItem';

/**
 * The NextManufacturerPromo component.
 */
export class NextManufacturerPromo extends React.Component {
  /**
   * Render promo feature table
   *
   * @returns {{}}
   */
  renderFeatureTable = () => {
    return (
      <div className="pc-feature-table">
        <div className="row header-row">
          <div className="feature-col">Features</div>
          <div className="pc-col">
            <img
              className="pc-logo"
              src={logo}
              alt="Project Content Logo"
            />
          </div>
          <div className="others-col">Some Have</div>
          <div className="others-col">All Have</div>
        </div>
        {FEATURES_TABLE_DATA.map((feature) => {
          return (
            <div key={feature.id} className="row">
              <div className="feature-col">{feature.feature}</div>
              <div className="pc-col"><FontAwesomeIcon icon={faCheck} /></div>
              <div className="others-col">{feature.some ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
              <div className="others-col" />
            </div>
          );
        })}
      </div>
    );
  }

  /**
   * Renders the promo page.
   *
   * @returns {{}}
   */
  renderView = () => {
    const {highlightedPlan} = this.props;

    return (
      <>
        <h1>What&apos;s Included?</h1>
        <div className="next-promo-container">
          {highlightedPlan && (
            <div className="next-plan-item">
              <PlanItem
                plan={highlightedPlan}
                planIndex={0}
                planCount={4}
                emphasizedMessage="Lifetime Coverage at No Cost"
              />
            </div>)}
          <div>{this.renderFeatureTable()}</div>
        </div>
      </>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
    <>{this.renderView()}</>
    );
  }
}

NextManufacturerPromo.propTypes = {
  highlightedPlan: PropTypes.object.isRequired,
  apiPlanGetAllStore: MobxPropTypes.observableObject,
};

NextManufacturerPromo.wrappedComponent = {};
NextManufacturerPromo.wrappedComponent.propTypes = {
  apiPlanGetAllStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(NextManufacturerPromo)(
  observer(NextManufacturerPromo)
);
