export const FEATURES_TABLE_DATA = [
  {
    id: 1,
    feature: 'Robust/Customized Content Library',
    some: true,
  },
  {
    id: 2,
    feature: 'Seamless Integration Between Content Creation and Scheduling',
    some: true,
  },
  {
    id: 3,
    feature: 'Proprietary Presto Content Editor',
    some: false,
  },
  {
    id: 4,
    feature: 'Mobile-Optimized Scheduling',
    some: false,
  },
  {
    id: 5,
    feature: 'Weighted Content Scheduling',
    some: false,
  },
  {
    id: 6,
    feature: 'Proof of Performance Reporting',
    some: true,
  },
  {
    id: 7,
    feature: 'Flexible Options for Content Creation',
    some: true,
  },
  {
    id: 8,
    feature: 'Unlimited Stock Imagery',
    some: true,
  },
  {
    id: 9,
    feature: 'Cloud-Based Software (Access from Anywhere)',
    some: true
  },
  {
    id: 10,
    feature: 'Instantly Auto Size Content to Any Size of Sign',
    some: false,
  },
  {
    id: 11,
    feature: 'Access to Premade Templates',
    some: true
  },
  {
    id: 12,
    feature: 'Limited Content Library',
    some: true
  }
];

